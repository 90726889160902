import Create from './Create';
import Delete from './Delete';
import Update from './Update';
import UpdateUnique from './UpdateUnique';
import UploadSubscribeCertificate from './UploadSubscribeCertificate';
import ShowCertificate from './ShowCertificate';
import Cracha from './Cracha';

import CrachaLote from './CrachaLote';
import QrCode from './QrCode';
import Credenciar from './Credenciar';

import ApplyToCredentiated from './ApplyToCredentiated';
import ChangeCrachaConfig from './ChangeCrachaConfig';
import UploadStudents from './UploadStudents';
import Balls from './Balls';
const lineModules = {
    create: Create,
    delete: Delete,
    update: Update,
    updateUnique: UpdateUnique,
    uploadSubscribeCertificate: UploadSubscribeCertificate,
    showCertificate: ShowCertificate,
    cracha: Cracha,
    crachaLote: CrachaLote,
    qrCode: QrCode,
    credenciar: Credenciar,
    ApplyToCredentiated: ApplyToCredentiated,
    ChangeCrachaConfig: ChangeCrachaConfig,
    UploadStudents: UploadStudents,
    Balls: Balls,
}

export default lineModules;