const defaultProperties = [


    { label: 'Largura máxima da div', style: 'max-width', inputType: 'text', defaultValue: '' },
    { label: 'Largura mínima da div', style: 'min-width', inputType: 'text', defaultValue: '' },
    { label: 'Display', style: 'display', inputType: 'select', options: ['flex', 'inline', 'block', 'none'], default: 'flex' },

    { label: 'Disposição dos elementos', style: 'flex-direction', inputType: 'select', options: ['row', 'column', 'column-reverse'], default: 'row' },
    { label: 'Gap', style: 'gap', inputType: 'text', default: '5px' },

    { label: 'Alinhamento de div Horizontal', style: 'justify-content', inputType: 'select', options: ['flex-start', 'flex-end', 'center', 'space-between', 'space-around'], default: '' },
    { label: 'Alinhamento de div Vertical', style: 'align-items', inputType: 'select', options: ['flex-start', 'flex-end', 'center', 'space-between', 'space-around'], default: '' },


    { label: 'Cor da fonte', style: 'color', inputType: 'color', defaultValue: '#ffffff' },
    { label: 'Alinhamento de texto', style: 'text-align', inputType: 'select', options: ['left', 'center', 'right', 'justify'], defaultValue: 'left' },
    { label: 'Tam. da Fonte', style: 'font-size', inputType: 'text', defaultValue: '' },
    { label: 'Espessura da fonte', style: 'font-weight', inputType: 'text', defaultValue: '' },
    { label: 'Espaço entre linhas', style: 'line-height', inputType: 'text', defaultValue: '' },
    { label: 'Margem interna superior', style: 'padding-top', inputType: 'text', defaultValue: '' },
    { label: 'Margem interna inferior', style: 'padding-bottom', inputType: 'text', defaultValue: '' },
    { label: 'Margem interna direita', style: 'padding-right', inputType: 'text', defaultValue: '' },
    { label: 'Margem interna esquerda', style: 'padding-left', inputType: 'text', defaultValue: '' },
    { label: 'Margem externa superior', style: 'margin-top', inputType: 'text', defaultValue: '' },
    { label: 'Margem externa inferior', style: 'margin-bottom', inputType: 'text', defaultValue: '' },
    { label: 'Margem externa direita', style: 'margin-right', inputType: 'text', defaultValue: '' },
    { label: 'Margem externa esquerda', style: 'margin-left', inputType: 'text', defaultValue: '' },
    { label: 'Cor de Fundo', style: 'background', inputType: 'color', defaultValue: '#ffffff' },
    { label: 'Imagem de Fundo', style: 'background-image', inputType: 'image', defaultValue: '' },
    { label: 'Transparência da imagem', style: 'background-opacity', inputType: 'input', defaultValue: '' },
    { label: 'Estilo de imagem de fundo', style: 'background-size', inputType: 'text', defaultValue: '' },
    { label: 'Centralização de imagem de fundo', style: 'background-position', inputType: 'text', defaultValue: '' },
    { label: 'Posição da DIV', style: 'position', inputType: 'text', defaultValue: '' },
    { label: 'TOP', style: 'top', inputType: 'text', defaultValue: '' },
    { label: 'Bottom', style: 'bottom', inputType: 'text', defaultValue: '' },
    { label: 'Right', style: 'right', inputType: 'text', defaultValue: '' },
    { label: 'Left', style: 'left', inputType: 'text', defaultValue: '' },
    { label: 'Borda direita', style: 'border-right', inputType: 'text', defaultValue: '' },
    { label: 'Borda esquerda', style: 'border-left', inputType: 'text', defaultValue: '' },
    { label: 'Borda superior', style: 'border-top', inputType: 'text', defaultValue: '' },
    { label: 'Borda inferior', style: 'border-bottom', inputType: 'text', defaultValue: '' },
    { label: 'Borda geral', style: 'border', inputType: 'text', defaultValue: '' },
];

export default defaultProperties;