import React, { useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { FiMail, FiUser, FiLock, FiMap, FiArrowRight } from 'react-icons/fi';
import { Link, useParams } from 'react-router-dom';
import {
  FaWhatsapp,
  FaFileMedical,
  FaNotesMedical,
  FaHospital,
  FaSimplybuilt,
  FaBuilding,
  FaIdBadge,
  FaClinicMedical,
} from 'react-icons/fa';

import { MdError, MdDateRange, MdChat } from 'react-icons/md';

import * as Yup from 'yup';
import ReCAPTCHA from 'react-google-recaptcha';
import { FormHandles } from '@unform/core';
import Input from '../../../components/Forms/Input';
import Button from '../../../components/Forms/Button';
import Loader from '../../../components/Loader';
import MaskedInput from '../../../components/Forms/MaskedInput';
import { ResponseMessage } from '../../../components/ResponseMessage';
import getValidationErrors from '../../../utils/getValidationErrors';
import { Error } from './styles';
import SelectSimple from '../../../components/Forms/SelectSimple';
import Whatsapp from '../../../components/WhatsAppCompartilhar';

import api from '../../../services/api';
import { authTitle, recaptchaKey, urlWebsite } from '../../../config';

import TextareaInput from '../../../components/Forms/TextareaInput';
import { useToast } from '../../../hooks/Toast';
import CheckboxInput from '../../../components/Forms/CheckboxInput';
import getCep from '../../../services/cep';
import Axios from 'axios';

import { useLanguage } from '../../../hooks/Language';

import testCPF from '../../../utils/testCPF';
import { addYears, isValid } from 'date-fns';
import { useAuth } from '../../../hooks/Auth';
import { useModal } from '../../../hooks/Modal';
import SignIn from '../../SignIn';
interface IRecoverFormData {
  [key: string]: any;
}


interface IRead {
  [key: string]: any;
}

interface IOption {
  value: string;
  label: string;
}
interface IParams {
  projectHash: string;
  eventHash: string;
  productId: string;
}

interface ICaptcha {
  getValue(): string;
}

declare var window: any;

const FormContainerUpdate = ({ context = {} }) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const formRef = useRef<FormHandles>(null);
  const recaptchaRef = useRef(null);
  const { productId } = useParams<IParams>();



  const getCaptchaValue = (elem: ICaptcha): string => {
    return elem.getValue();
  };

  const { addToast } = useToast();
  const { removeModal } = useModal();
  const { translate } = useLanguage();


  const { user, signIn } = useAuth();


  const handleUpdate = async (data: IRecoverFormData) => {
    setLoading(true);



    if (user?.country === 'BR') {
      data.document_type = 'cpf';
    }

    try {
      if (!data?.birth_date) {
        addToast({ type: 'error', title: 'Preencha a sua data de nascimento' })
        setLoading(false);
        return;
      }

      const getDate = data?.birth_date?.split('/');

      const date = new Date(`${getDate?.[2]}-${getDate?.[1]}-${getDate?.[0]}`);

      if (!isValid(date)) {
        throw ({ 'message': 'Data de nascimento incompleta' });
      }

      if (date < addYears(new Date(), -100)) {
        throw ({ 'message': 'Data de nascimento inválida' });
      }

    } catch (err) {
      console.log(err)
      addToast({ type: 'error', title: 'Preencha uma data de nascimento válida' })
      setLoading(false);
      return;
    }


    try {
      formRef.current?.setErrors({});


      const shape = {
        birth_date: Yup.string().required('Informe a sua Data de nascimento'),
        document_type: Yup.string().required('Informe o seu tipo de documento'),
        term: Yup.string().required('Informe se você autoriza o compartilhamento de seus dados.'),
      };


      const schema = Yup.object().shape(shape);

      if (user?.country === 'BR') {
        const validCPF = testCPF(data?.document_number);

        if (!validCPF) {
          addToast({ type: 'error', title: 'Preencha um CPF válido' })
          setLoading(false);
          return;
        }
      }

      if (data.position === 'Médico' || data.position === 'Estudante' || data.position === 'Profissional da área da saúde') {

        if (!data.category_id) {
          addToast({ title: 'Por favor, informe sua área / especialidade', type: 'error' });
          setLoading(false);
          return;
        }

      }


      await schema.validate(data, { abortEarly: false });




      const captchaValue =
        getCaptchaValue(recaptchaRef?.current || { getValue: () => '' }) ||
        false;

      const content: Record<string, any> = {
        ...data,

        captcha: captchaValue,
        term: data.term,

      };

      if (productId) {
        content.product = productId;
      }


      const newUser = await api
        .post('/user-information-update/', content)
        .catch(error => {
          if (error instanceof Yup.ValidationError) {
            const errors = getValidationErrors(error);
            formRef.current?.setErrors(errors);
            setLoading(false);
          }

          addToast({
            type: 'error',
            title: error?.response?.data?.message || error?.response?.data?.error || 'Erro ao atualizar',
          });
          setLoading(false);
          return false;
        });

      if (!newUser) {
        setLoading(false);
        return false;
      }


      await signIn({ name: user?.name, email: user?.email })
      window.location.reload();

      setSuccess(true);
      setLoading(false);


      return false;
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);

        formRef.current?.setErrors(errors);

        const errorInfo = err.errors.reduce((error, prev) => {
          prev = `${prev}, ${error}`;
          return prev;
        });
        setLoading(false);
        addToast({
          type: 'error',
          title: 'Erro de validação',
          description: errorInfo,
        });
      }
      setLoading(false);
    }
  }














  return (
    <>
      {loading === true && (
        <Loader message="Preparando sua atualização..." position="fixed" />
      )}

      <aside>
        <>

          <Form ref={formRef} onSubmit={handleUpdate} initialData={{ country: 'BR' }}>
            <h1 style={{ color: 'rgb(0,15,64)', marginBottom: '20px' }}>Seja bem-vindo!</h1>
            <p style={{ color: 'rgb(0,15,64)', marginBottom: '20px' }}>Para continuar aproveitando os conteúdos da Plataforma Encontro Digital, pedimos que atualize o seu cadastro!</p>




            {user?.position === 'Médico' && <>


              <SelectSimple
                options={[


                  { value: 'Acupuntura', label: 'Acupuntura' },
                  { value: 'Alergia e imunologia', label: 'Alergia e imunologia' },
                  { value: 'Anestesiologia', label: 'Anestesiologia' },
                  { value: 'Angiologia', label: 'Angiologia' },
                  { value: 'Cardiologia', label: 'Cardiologia' },
                  { value: 'Cirurgia cardiovascular', label: 'Cirurgia cardiovascular' },
                  { value: 'Cirurgia da mão', label: 'Cirurgia da mão' },
                  { value: 'Cirurgia de cabeça e pescoço', label: 'Cirurgia de cabeça e pescoço' },
                  { value: 'Cirurgia do aparelho digestivo', label: 'Cirurgia do aparelho digestivo' },
                  { value: 'Cirurgia geral', label: 'Cirurgia geral' },
                  { value: 'Cirurgia oncológica', label: 'Cirurgia oncológica' },
                  { value: 'Cirurgia pediátrica', label: 'Cirurgia pediátrica' },
                  { value: 'Cirurgia plástica', label: 'Cirurgia plástica' },
                  { value: 'Cirurgia torácica', label: 'Cirurgia torácica' },
                  { value: 'Cirurgia vascular', label: 'Cirurgia vascular' },
                  { value: 'Clínica médica', label: 'Clínica médica' },
                  { value: 'Coloproctologia', label: 'Coloproctologia' },
                  { value: 'Dermatologia', label: 'Dermatologia' },
                  { value: 'Endocrinologia e metabologia', label: 'Endocrinologia e metabologia' },
                  { value: 'Endoscopia', label: 'Endoscopia' },
                  { value: 'Gastroenterologia', label: 'Gastroenterologia' },
                  { value: 'Genética médica', label: 'Genética médica' },
                  { value: 'Geriatria', label: 'Geriatria' },
                  { value: 'Ginecologia e obstetrícia', label: 'Ginecologia e obstetrícia' },
                  { value: 'Hematologia e hemoterapia', label: 'Hematologia e hemoterapia' },
                  { value: 'Homeopatia', label: 'Homeopatia' },
                  { value: 'Infectologia', label: 'Infectologia' },
                  { value: 'Mastologia', label: 'Mastologia' },
                  { value: 'Medicina de emergência', label: 'Medicina de emergência' },
                  { value: 'Medicina de família e comunidade', label: 'Medicina de família e comunidade' },
                  { value: 'Medicina do trabalho', label: 'Medicina do trabalho' },
                  { value: 'Medicina de tráfego', label: 'Medicina de tráfego' },
                  { value: 'Medicina esportiva', label: 'Medicina esportiva' },
                  { value: 'Medicina física e reabilitação', label: 'Medicina física e reabilitação' },
                  { value: 'Medicina intensiva', label: 'Medicina intensiva' },
                  { value: 'Medicina legal e perícia médica', label: 'Medicina legal e perícia médica' },
                  { value: 'Medicina nuclear', label: 'Medicina nuclear' },
                  { value: 'Medicina preventiva e social', label: 'Medicina preventiva e social' },
                  { value: 'Nefrologia', label: 'Nefrologia' },
                  { value: 'Neurocirurgia', label: 'Neurocirurgia' },
                  { value: 'Neurologia', label: 'Neurologia' },
                  { value: 'Nutrologia', label: 'Nutrologia' },
                  { value: 'Oftalmologia', label: 'Oftalmologia' },
                  { value: 'Oncologia clínica', label: 'Oncologia clínica' },
                  { value: 'Ortopedia e traumatologia', label: 'Ortopedia e traumatologia' },
                  { value: 'Otorrinolaringologia', label: 'Otorrinolaringologia' },
                  { value: 'Patologia', label: 'Patologia' },
                  { value: 'Patologia clínica/medicina laboratorial', label: 'Patologia clínica/medicina laboratorial' },
                  { value: 'Pediatria', label: 'Pediatria' },
                  { value: 'Pneumologia', label: 'Pneumologia' },
                  { value: 'Psiquiatria', label: 'Psiquiatria' },
                  { value: 'Radiologia e diagnóstico por imagem', label: 'Radiologia e diagnóstico por imagem' },
                  { value: 'Radioterapia', label: 'Radioterapia' },
                  { value: 'Reumatologia', label: 'Reumatologia' },
                  { value: 'Urologia', label: 'Urologia' },
                  { value: 'Outra', label: 'Outra' },

                ]}
                name="category_id"
                formRef={formRef}
                label="Especialidade"
              /> </>}

            {user?.position === 'Profissional da área da saúde' ? <SelectSimple
              options={[
                { value: "Enfermagem", label: "Enfermagem" },
                { value: "Fonoaudiologia", label: "Fonoaudiologia" },
                { value: "Psicologia", label: "Psicologia" },
                { value: "Fisioterapia", label: "Fisioterapia" },
                { value: "Farmácia", label: "Farmácia" },
                { value: "Terapia Ocupacional", label: "Terapia Ocupacional" },
                { value: 'Outra', label: 'Outra' },

              ]}
              name="category_id"
              formRef={formRef}
              label="Área"
            /> : <></>}

            {user?.position === 'Estudante' ?
              <SelectSimple
                name="category_id"
                formRef={formRef}
                label="Área"
                options={[
                  { value: "Biomedicina", label: "Biomedicina" },
                  { value: "Biotecnologia", label: "Biotecnologia" },
                  { value: "Educação Física", label: "Educação Física" },
                  { value: "Enfermagem", label: "Enfermagem" },
                  { value: "Farmácia", label: "Farmácia" },
                  { value: "Fisioterapia", label: "Fisioterapia" },
                  { value: "Fonoaudiologia", label: "Fonoaudiologia" },
                  { value: "Medicina", label: "Medicina" },
                  { value: "Nutrição", label: "Nutrição" },
                  { value: "Odontologia", label: "Odontologia" },
                  { value: "Pedagogia", label: "Pedagogia" },
                  { value: "Psicologia", label: "Psicologia" },
                  { value: "Saúde Coletiva", label: "Saúde Coletiva" },
                  { value: "Serviço Social", label: "Serviço Social" },
                  { value: 'Outra', label: 'Outra' },
                ]} />


              : <></>}







            {user?.country !== 'BR' ?

              <SelectSimple options={[
                { value: 'passport', label: translate('Passaporte') },
                { value: 'other', label: translate('Documento local') },
              ]} name="document_type" label={'Tipo de documento pessoal'}
              />



              : <></>}

            {user?.country === 'BR' ?
              <MaskedInput
                mask={'999.999.999-99'}
                name="document_number"
                placeholder={'CPF'}
              />
              : <Input type='text' name="document_number" placeholder={translate("Número do documento")} />}

            <MaskedInput
              mask="99/99/9999"
              name="birth_date"
              placeholder="Data de nascimento (DD/MM/AAAA)"
            />

            <div style={{ marginBottom: '10px', padding: '15px', borderRadius: '10px' }}>
              <CheckboxInput
                style={{ display: 'flex' }}
                name="term"
                unique={true}
                preText={(
                  <>
                    {' '}
                    Estou ciente de que estou fornecendo à {authTitle} e seus parceiros meus
                    contatos gerais, os quais são dados pessoais protegidos pela
                    Lei Geral de Proteção de Dados brasileira, para gestão de
                    relacionamento e marketing da empresa, conforme Política de Privacidade disponível neste site.
                    {' '}

                  </>
                )}
                options={[
                  {
                    id: `Sim, eu autorizo o compartilhamento dos meus dados`,
                    label: 'Sim, eu autorizo o compartilhamento dos meus dados',
                    value: `Sim, eu autorizo o compartilhamento dos meus dados`,
                  },
                  {
                    id: `Não autorizo o compartilhamento dos meus dados`,
                    label: 'Não autorizo o compartilhamento dos meus dados',
                    value: `Não autorizo o compartilhamento dos meus dados`,
                  },
                ]}
              />

              <p
                style={{
                  padding: '10px',
                  background: 'none',
                  margin: '5px auto',
                  color: '#333',
                  fontSize: '12px',
                  textAlign: 'left',
                }}
              >
                {'Ao me cadastrar, afirmo que li a'}
                {' '}
                <strong>
                  <Link
                    style={{ display: 'inline', color: '#333' }}
                    to="/termos"
                    target="_BLANK"
                  >
                    {'Política de Privacidade'}
                    {' '}
                  </Link>
                  {' '}
                </strong>
                {'desta plataforma.'}
              </p>
            </div>
            <aside style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <ReCAPTCHA ref={recaptchaRef} sitekey={recaptchaKey} />

            </aside>  <aside style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <button className="defaultButtonReverse" type="submit">
                Atualizar cadastro


              </button>
            </aside>
          </Form>
        </>

      </aside>
    </>
  );
};

export default FormContainerUpdate;
