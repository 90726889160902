import React, { useEffect, useState } from 'react';
import { useConfiguration } from '../../../../../hooks/Configuration';

import Program from '../../Program';
import Speakers from '../../../../../pages/Speakers';
import SponsorsPage from '../../../../../pages/SponsorsPage';
import HomeApplication from '../../Home';
import CustomPage from '../../CustomPage';
import Subscription from '../../../../../pages/Subscription';

import SpeakersNoSql from '../../SpeakersNoSQL';
import SponsorsNoSql from '../../SponsorsPlatform';

import { ContainerWhite } from './styles';
import SponsorsPageByHashWithSize from '../../../../../pages/SponsorsPageByHashWithSize';
import Paginas from '../../Paginas';
const Template: React.FC = () => {
  const { configuration } = useConfiguration();

  const { projectHash = configuration?.url, eventHash } = useConfiguration();
  const [layoutElements, setLayoutElements] = useState<Array<JSX.Element>>([]);


  const eventPageLayout = configuration?.current_event_id_response?.landing_page ? configuration?.current_event_id_response?.landing_page : [
    { reference: 'home' },

    { reference: 'speaker' },
    { reference: 'program' },

    { reference: 'sponsor' },


  ]

  const generatePage = () => {

    const valid = {
      home: ({ hash }) => <HomeApplication />,
      program: (hash) => <Program />,
      speaker: ({ hash }) => <ContainerWhite style={{ flexDirection: 'column', background: '#fff' }}><SpeakersNoSql projectHash={projectHash} /></ContainerWhite>,
      custom_page: ({ hash }) => <Paginas hash={hash} />,
      sponsor: ({ hash }) => <SponsorsPage />,
      sale: ({ hash }) => <Subscription />,
    }

    const items: Array<JSX.Element> = [];

    eventPageLayout.map(layoutItem => {


      if (valid?.[layoutItem?.reference]) {
        items.push(valid[layoutItem.reference]({ hash: layoutItem?.hash || '' }));
      }

    })

    setLayoutElements(items);

  }

  useEffect(() => {
    if (projectHash) {
      generatePage();
    }
  }, [projectHash, eventHash])


  return <>{layoutElements}

    {configuration && configuration?.theme_data?.global_speakers ? <ContainerWhite style={{ flexDirection: 'column', background: '#fff' }} id="comissao">
      <SpeakersNoSql projectHash={configuration?.theme_data?.global_speakers} />
    </ContainerWhite> : <></>}

    {configuration && configuration?.theme_data?.global_sponsors ? <ContainerWhite style={{ flexDirection: 'column', background: '#fff' }} id="comissao">
      <SponsorsPageByHashWithSize projectHash={configuration?.theme_data?.global_sponsors} />
    </ContainerWhite> : <></>}


  </>;
};
export default Template;
