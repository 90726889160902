import React, { useState, useEffect } from 'react';

import { IconType } from 'react-icons/lib';

import { FaChevronDown, FaChevronUp, FaList } from 'react-icons/fa';
import { FiGrid } from 'react-icons/fi';
import { Container, ContainerWhite, Evento } from './styles';

import { SpeakersContainer } from './modules/SpeakersGridNoSQL';
import api from '../../../../services/api';
import { useParams } from 'react-router-dom';
import Axios from 'axios';

interface ProgramData {
  email: string;
  password: string;
}

interface IPublicData {
  icon: IconType;
  title: string;
  text: string;
}

interface ISpeakerData {
  name: string;
  photo: string;
  position?: string;
  description?: string;
  category_id: number;
  id: number;
  category_response?: Record<any, string>;
  social?: Record<any, string>;

}

interface ISpeakers {
  title: string;
  position: string;
  order_type: string;
  speakers: Array<ISpeakerData>;
}
interface IParamsURL {
  projectHash?: string;
  eventHash?: string;
}

const removerAcentos = (value) => {

  value = value.toUpperCase();
  value = value.replace('Í', 'I').replace('Ì', 'I').replace('Ĩ', 'I').replace('Ï', 'I').replace('Î', 'I');
  value = value.replace('Á', 'A').replace('À', 'A').replace('Ã', 'A').replace('Ä', 'A').replace('Â', 'A');
  value = value.replace('É', 'E').replace('È', 'E').replace('Ẽ', 'E').replace('Ë', 'E').replace('Ê', 'E');
  value = value.replace('Ó', 'O').replace('Ò', 'O').replace('Õ', 'O').replace('Ö', 'O').replace('Ô', 'O');
  value = value.replace('Ú', 'U').replace('Ù', 'U').replace('Ũ', 'U').replace('Ü', 'U').replace('Û', 'U');
  value = value.replace('Ç', 'C');
  value = value.replace("'", "");
  value = value.replace("'", "");
  value = value.replace("`", "");
  value = value.replace("'", "");

  return value;

}

const SpeakersNoSQL: React.FC<IParamsURL> = ({ projectHash, eventHash }) => {
  const [speakersData, setSpeakerData] = useState<Array<ISpeakers>>([]);




  const orderList = (item) => {

    const valid = {
      asc: (speakers) => {

        if (speakers.length > 0) {
          speakers.sort((a, b) => {
            return removerAcentos(a.name) > removerAcentos(b.name) ? 1 : removerAcentos(a.name) < removerAcentos(b.name) ? -1 : 0
          })
        }

        return speakers;

      },
      desc: (speakers) => {

        if (speakers.length > 0) {
          speakers.sort((a, b) => {
            return removerAcentos(a.name) < removerAcentos(b.name) ? 1 : removerAcentos(a.name) > removerAcentos(b.name) ? -1 : 0
          })
        }

        return speakers;

      },
    }


    if (valid[item?.order_type]) {
      item.speakers = valid[item?.order_type](item?.speakers);
    }

    return item.speakers || [];

  }

  useEffect(() => {
    const load = async () => {
      const order = { orderBy: 'order_id', direction: 'asc' };

      const string = `order=${encodeURIComponent(JSON.stringify(order))}`;

      const speakers: Array<ISpeakers> = [
        {
          title: 'Palestrantes',
          speakers: [],
          position: '1',
          order_type: 'asc'
        },

      ];

      const response = await Axios.get(`https://api-global-v2.encontrodigital.com.br/999e410f-3518-4806-82e7-b8e01eb763e4/speakers-list/${projectHash}`, { params: { limitItems: 100 } });



      if (response) {

        let newSpeakers: Array<ISpeakers> = [];

        response.data.map((a: ISpeakers) => {

          a.speakers = orderList(a);

          newSpeakers.push(a);



        })

        newSpeakers.sort((a, b) => {
          return a.position > b.position ? 1 : a.position < b.position ? -1 : 0
        })



        setSpeakerData(newSpeakers);
      }
    };
    load();
  }, []);

  return speakersData.length > 0 ? <Container  > {speakersData.map(s => <SpeakersContainer speakers={s.speakers} title={s.title} />)}</Container> : <></>;
};

export default SpeakersNoSQL;
