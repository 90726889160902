import React, { useCallback, useRef, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useLanguage } from '../../hooks/Language';
import { useConfiguration } from '../../hooks/Configuration';

import DefaultTheme from './modules/Themes/Default';
import DasaTheme from './modules/Themes/SidebarTheme';
import SinglePageTheme from './modules/Themes/SinglePageTheme';
import SidebarEventTheme from './modules/Themes/SidebarThemeEvents';

interface IParams {
  projectHash: string;
}

const HomeApplication: React.FC = () => {


  const { translate } = useLanguage();

  const { configuration } = useConfiguration();

  const [theme, setTheme] = useState('');

  const { projectHash, eventHash } = useParams<Record<string, any>>();

  useEffect(() => {

    let themeOption = configuration?.theme_data?.["page-theme"] || 'single_page';

    if (themeOption === 'single_page' && eventHash) {
      themeOption = 'sidebar_event_theme';
    }

    setTheme(themeOption || 'single_page')

  }, [configuration])

  const themes = {
    default: <SinglePageTheme />,
    dasa: <SinglePageTheme />,
    single_page: <SinglePageTheme />,
    sidebar_event_theme: <SinglePageTheme />

  }



  return (themes?.[theme] ? themes?.[theme] : <></>);
};
export default HomeApplication;
