import React from 'react';

import {
  SearchComponent,
  ISearchComponent,
} from '../../core/components/SearchComponent';
import pageCreatorWidget from './modules/pageCreatorWidget';

import lineModules from '../../core/components/lineModules'
import { Container } from './styles';
import { useParams } from 'react-router-dom';

interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}
interface IParams {
  projectHash: string;
  eventHash: string;
}


const EventsPagesAdmin: React.FC = () => {
  const endpoint = '/events-pages';
  const title = 'Páginas Avulsas';

  const { projectHash, eventHash } = useParams<IParams>(); const schema: Record<string, any> = {
    _id: { ref: '_id', column: '_id', label: 'ID', show: false },
    content: { ref: 'content', column: 'content', label: 'Conteúdo', show: false },
    title: { ref: 'title', column: 'title', label: 'Título' },
    url: { ref: 'url', column: 'url', label: 'URL' },
    description: { ref: 'description', column: 'description', label: 'Descritivo', show: false },


    createdAt: {
      ref: 'created_at',
      column: 'createdAt',
      label: 'Data de Criação',
      mask: 'date',
    },
  };

  const formSchema = {

    image: {
      model: 'imageSingle',
      type: 'text',
      name: 'image',
      label: 'Foto',
      list: [
        { label: 'Imagem', name: 'url', type: 'input' },

      ],
    },
    url: { model: 'input', type: 'text', name: 'url', label: 'URL' },
    title: { model: 'input', type: 'text', name: 'title', label: 'Título' },
    description: { model: 'richText', type: 'text', name: 'description', label: 'Descritivo' },
    video: { model: 'input', type: 'text', name: 'video', label: 'Link de Vídeo' },
    map_link: { model: 'input', type: 'text', name: 'map_link', label: 'Link do mapa' },
    orientation: {
      model: 'selectSimple',
      name: 'orientation',
      label: 'Orientação',
      defaultValue: 'line',
      options: [
        { label: 'Linha', value: 'line' },
        { label: 'Coluna', value: 'column' },
      ]
    },
    status: {
      model: 'selectSimple',
      name: 'status',
      label: 'Ativo?',
      defaultValue: 'Sim',
      options: [
        { label: 'Sim', value: 'yes' },
        { label: 'Não', value: 'no' },
      ]
    },
    files: {
      model: 'imageGalleryInput',
      type: 'input',
      name: 'files',
      label: 'Arquivos',
      list: [
        { label: 'Arquivo', name: 'url' },
        { label: 'Título', name: 'title' },


      ],

    },
    buttons: {
      model: 'jsonListMultiple',
      type: 'text',
      name: 'buttons',
      label: 'Botões',
      list: {
        title: { label: 'Título', name: 'title' },
        link: { label: 'Link', name: 'link' },
        class_name: { label: 'Tipo de botão', name: 'class_name' },
      },
    },

    images: {
      model: 'imageGalleryInput',
      type: 'input',
      name: 'images',
      label: 'Imagens',
      list: [
        { label: 'Imagem', name: 'url' },
        { label: 'Título', name: 'title' },
        { label: 'Classe', name: 'class_name' },
        { label: 'Link', name: 'link_url' },

      ],
    },
    theme_data: {
      model: 'jsonList',
      type: 'text',
      name: 'theme_data',
      label: 'Personalização',
      list: [
        { label: 'Altura mínima da página', name: 'min_height' },
        { label: 'Fundo', name: 'background' },
        { label: 'Cor da linha', name: 'line_color' },
        { label: 'Cor do título', name: 'title_color' },
        { label: 'Cor do Texto', name: 'text_color' },
        { label: 'Template de imagens', name: 'image_template' },
        { label: 'Esconder bloco lateral?', name: 'hide_side_content' },
        { label: 'Template da página', name: 'template' },
        { label: 'Transparência do fundo', name: 'background_opacity' },
        { label: 'Transparência do overlay', name: 'background_overlay' },
        { label: 'Fundo Fixo?', name: 'fixed_image' },
        { label: 'Adicionar efeito?', name: 'particles' },
        { label: 'Código de formulário', name: 'forms_code' },
        { label: 'Tamanho mínimo da página', name: 'min_height' },
        { label: 'Posição da imagem de funco', name: 'background_position' },
        { label: 'Template de lista', name: 'list_template' },

      ],
    },

    testimonial_list: {
      model: 'jsonInputList',
      type: 'text',
      name: 'testimonial_list',
      label: 'Lista de Depoimentos',
      show: 'title',
      list: [
        { label: 'Imagem', name: 'image', type: 'imageSingle' },
        { label: 'Título', name: 'title', type: 'input' },
        { label: 'Subtítulo', name: 'subtitle', type: 'input' },
        { label: 'Descritivo', name: 'description', type: 'richText' },


      ]
    },

    data_list: {
      model: 'jsonInputList',
      type: 'text',
      name: 'data_list',
      label: 'Lista Geral',
      show: 'title',
      list: [
        { label: 'Imagem', name: 'image', type: 'imageSingle' },
        { label: 'Título', name: 'title', type: 'input' },
        { label: 'Subtítulo', name: 'subtitle', type: 'input' },
        { label: 'Descritivo', name: 'description', type: 'richText' },
        {
          label: 'Personalização', name: 'theme', show: ['column', 'value'], type: 'jsonInputList', list: [
            { label: 'Campo', name: 'column', type: 'input' },
            { label: 'Valor', name: 'value', type: 'input' }
          ]
        },
        {
          label: 'Links', name: 'links', show: ['title', 'url'], type: 'jsonInputList', list: [
            { label: 'Título', name: 'title', type: 'input' },
            { label: 'Url', name: 'url', type: 'input' }
          ]
        },
        { label: 'Url de vídeo', name: 'video_url', type: 'input' },
        {
          label: 'Redes sociais', name: 'social', show: ['type', 'url'], type: 'jsonInputList', list: [
            {
              label: 'Rede Social', name: 'type', type: 'select', options: [
                { label: 'Facebook', value: 'facebook' },
                { label: 'Instagram', value: 'instagram' },
                { label: 'Linkedin', value: 'linkedin' },
                { label: 'Spotify', value: 'spotify' },
                { label: 'Tiktok', value: 'tiktok' },
                { label: 'Youtube', value: 'youtube' },
                { label: 'Whatsapp', value: 'whatsapp' },
              ]
            },
            { label: 'Link', name: 'url', type: 'input' }
          ]
        },

      ]
    },
  };

  const formSchemaUpdate = {


    ...formSchema



  };

  const formValidation: Record<string, ValidationSchema> = {

  };

  const config: ISearchComponent = {
    idKey: `${endpoint}-${projectHash}-${eventHash}`,
    title,
    endpoint,
    schema,
    autoStart: true,
    headModules: [
      () =>
        lineModules.create({
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
    bodyModules: [
      (lineData: Record<string, any>) =>
        lineModules.updateUnique({
          lineData,
          title,
          formSchema: formSchemaUpdate,
          validationSchema: {},
          endpoint,
        }),
      (lineData: Record<string, any>) =>
        lineModules.delete({
          lineData,
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
      (lineData: Record<string, any>) => pageCreatorWidget({ lineData })
    ],
  };

  return (
    <Container>
      <SearchComponent {...config} />
    </Container>
  );
};

export default EventsPagesAdmin;
