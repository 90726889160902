import id from 'date-fns/esm/locale/id/index.js';
import React from 'react';
import { Link } from "react-router-dom";
import IframePlayerMobile from '../components/IframePlayerMobile';
import IframePlayer from '../components/IframePlayer';
interface IProps {
    data?: Record<string, any>;
    computer?: Record<string, any>;
    mobile?: Record<string, any>;
}

const IframeWidget: React.FC<IProps> = ({ data, computer, mobile }) => {

    return <> {data?.map(iframeData => <div className='column'>
        {iframeData?.type === 'vertical' ? <IframePlayerMobile id={iframeData?.url || ''} /> : iframeData?.type === 'free' ? <iframe src={iframeData?.url || ''} style={{ width: '100%', minHeight: data?.minHeight || '200px' }} /> : <IframePlayer id={data?.url || ''} />}
        {iframeData?.title ? <h2 style={{ fontSize: '16px', color: computer?.color || '#333' }}>{iframeData?.title}</h2> : <></>}
        {iframeData?.description ? <p style={{ fontSize: '14px', color: computer?.color || '#333' }} dangerouslySetInnerHTML={{ __html: iframeData?.description }} /> : <></>}
    </div>
    )}</>
}



export default IframeWidget;