import React from 'react';

import {
  SearchComponent,
  ISearchComponent,
} from '../../core/components/SearchComponent';

import lineModules from '../../core/components/lineModules'
import { Container } from './styles';
import { useParams } from 'react-router-dom';
import { AplicarCortesia } from './modules/AplicarCortesia';
import { useAuth } from '../../../../hooks/Auth';
import { useConfiguration } from '../../../../hooks/Configuration';

interface ValidationRules {
  type: 'email' | 'required' | 'min' | 'max' | 'length';
  message?: string;
  quantity?: number;
}

interface ValidationSchema {
  name: string;
  type: 'string' | 'number' | 'boolean';
  label: string;
  rules: Array<ValidationRules>;
}

interface ILine {
  [key: string]: string;
}
interface IParams {
  projectHash: string;
  eventHash: string;
}


export const searchable: Record<string, any> = {
  _id: { ref: '_id', column: '_id', label: 'ID', show: false },
  project_id: { ref: 'project_id', column: 'project_id', label: 'Projeto' },
  user_hash: { ref: 'user_hash', column: 'user_hash', label: 'Hash', show: false },
  event_id: { ref: 'event_id', column: 'event_id', label: 'Evento', show: false },
  reference: { ref: 'reference', column: 'reference', label: 'Credenciado?' },
  name: { ref: 'name', column: 'name', label: 'Nome' },
  email: { ref: 'email', column: 'email', label: 'Email' },
  document_number: {
    ref: 'document_number',
    column: 'document_number',
    label: 'CPF', show: false,
  },
  inscricao_1: { ref: 'inscricao_1', column: 'inscricao_1', label: 'Entidade' },
  cpf_familiar: { ref: 'cpf_familiar', column: 'cpf_familiar', label: 'Curso' },
  company: {
    ref: 'company',
    column: 'company',
    label: 'Empresa', show: false,
  },
  city: {
    ref: 'city',
    column: 'city',
    label: 'Cidade', show: false,
  },
  state: {
    ref: 'state',
    column: 'state',
    label: 'Estado', show: false,
  },
  company_document_number: {
    ref: 'company_document_number',
    column: 'company_document_number',
    label: 'CNPJ', show: false,
  },
  position: {
    ref: 'position',
    column: 'position',
    label: 'Cargo', show: false,
  },
  updated_information: {
    ref: 'updated_information',
    column: 'updated_information',
    label: 'Atualizado?', show: false,
  },

  createdAt: {
    ref: 'created_at',
    column: 'createdAt',
    label: 'Data de Criação',
    mask: 'date',
  },
};

const Dashboard: React.FC = () => {
  const endpoint = '/students';
  const title = 'Participantes';
  const { projectHash, eventHash } = useParams<IParams>();
  const { user } = useAuth();

  const { configuration } = useConfiguration();

  const schema: Record<string, any> = {
    _id: { ref: '_id', column: '_id', label: 'ID', show: false },
    project_id: { ref: 'project_id', column: 'project_id', label: 'Projeto' },
    event_id: { ref: 'event_id', column: 'event_id', label: 'Evento', show: false },
    reference: { ref: 'reference', column: 'reference', label: 'Credenciado?' },
    subscription_text: { ref: 'subscription_text', column: 'subscription_text', template: projectHash, label: 'Ingresso', show: false },
    name: { ref: 'name', column: 'name', label: 'Nome' },
    email: { ref: 'email', column: 'email', label: 'Email' },
    document_number: {
      ref: 'document_number',
      column: 'document_number',
      label: 'CPF', show: false,
    },
    cpf_familiar: {
      ref: 'cpf_familiar',
      column: 'cpf_familiar',
      label: 'Categoria', show: false,
    },
    position: {
      ref: 'position',
      column: 'position',
      label: 'Especialidade', show: false,
    },
    updated_information: {
      ref: 'updated_information',
      column: 'updated_information',
      label: 'Atualizado?', show: false,
    },

    createdAt: {
      ref: 'created_at',
      column: 'createdAt',
      label: 'Data de Criação',
      mask: 'date',
    },
  };

  configuration?.current_event_id_response?.sign_up_fields?.map(field => {
    schema[field?.fields] = {
      ref: field?.fields,
      column: field?.fields,
      label: field?.title,
    };


  })

  const formSchema = {
    name: { model: 'input', type: 'text', name: 'name', label: 'Nome' },
    extra_1: { model: 'input', type: 'text', name: 'extra_1', label: 'Observações' },
    email: { model: 'input', type: 'text', name: 'email', label: 'Email' },
    document_number: { model: 'input', type: 'text', name: 'document_number', label: 'CPF' },
    zipcode: { model: 'input', type: 'text', name: 'zipcode', label: 'CEP' },
    instituicao: {
      model: 'input',
      type: 'text',
      name: 'instituicao',
      label: 'Instituição',
    },

    password_hash: {
      model: 'input',
      type: 'password',
      name: 'password_hash',
      label: 'Senha',
    },
    profile: {
      model: 'selectSimple',
      name: 'profile',
      label: 'Nível',
      defaultValue: '2',
      options: user?.profile === 1 ? [
        { label: 'Administrador', value: 1 },
        { label: 'Atendente de credenciamento', value: 35 },
        { label: 'Leitor de porta', value: 30 },
        { label: 'Participante', value: 2 },
        { label: 'Mediador / Palestrante', value: 3 },
        { label: 'Organização', value: 4 },
        { label: 'Expositor', value: 5 },
        { label: 'Moderador de Chat', value: 6 },
        { label: 'Leitor', value: 8 },
        { label: 'Gestor de conta', value: 9 },
      ] :
        [

          { label: 'Atendente de credenciamento', value: 35 },
          { label: 'Leitor de porta', value: 30 },
          { label: 'Participante', value: 2 },

          { label: 'Organização', value: 4 },

          { label: 'Moderador de Chat', value: 6 },
        ]


      ,
    },

  };

  const formValidation: Record<string, ValidationSchema> = {

  };


  const config: ISearchComponent = {
    idKey: `${endpoint}-${projectHash}-${eventHash}`,
    title,
    endpoint,
    schema,
    autoStart: true,
    headModules: [
      () =>
        lineModules.create({
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
    ],
    bodyModules: [
      (lineData: Record<string, any>) =>
        lineModules.updateUnique({
          lineData,
          title,
          formSchema: formSchema,
          validationSchema: {},
          endpoint,
        }),
      (lineData: Record<string, any>) =>
        lineModules.delete({
          lineData,
          title,
          formSchema,
          validationSchema: formValidation,
          endpoint,
        }),
      (lineData: Record<string, any>) =>
        AplicarCortesia({
          lineData,

        }),
    ],
  };

  return (
    <Container>
      <SearchComponent {...config} />
    </Container>
  );
};

export default Dashboard;
