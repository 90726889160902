export const hash = '999e410f-3518-4806-82e7-b8e01eb763e4';

// export const gateKey = '';
export const gateKey = 'ek_live_g5Df6bp6y5ptAKeOVOpBloZHmqY0sb';
export const recaptchaKey = '6Lc65asZAAAAAGFrDIgIo83z60UALaedFZHfWSYd';
export const apiUrl = `https://api-global-v3.encontrodigital.com.br/${hash}`;
export const apiSocket = 'https://socket-2023.encontrodigital.com.br/';
//export const apiSocket = 'http://localhost:8001';

//export const apiUrl = `http://localhost:8000/${hash}`;
export const defaultProjectHash = '';
export const urlWebsite = 'https://bariatricasulv2.encontrodigital.com.br';
export const websitePath = 'https://bariatricasulv2.encontrodigital.com.br';
export const supportNumber = `555195181533`;
export const home = 'Home';
export const haveTranslate = false;
// export const urlWebsite = 'http://localhost:3000';
export const eventName = 'Bariátrica Sul';
export const logo = '/assets/logo.png';
export const background = '/assets/evento/Fundo.jpeg';
export const authTitle = 'Bariátrica Sul';
export const dadosOrganizacao = '';
export const social = {
  whatsapp: '',
  instagram: '',
  facebook: '',
  linkedin: '',
  youtube: '',
  twitter: '',
  website: ''
};


export const defaultTheme = {
  defaultBackground: 'rgb(0,50,150)',
  defaultColor: '#fff',
  logo: {
    minWidth: '450px',
  },
  menu: {
    background: 'none',
    backgroundSticky: 'none',
    backgroundMobile: 'rgb(0,50,150)',
    color: '#fff',
    shadow: '0',
    logoColor: 'none',
  },
};

export const getToken = (): string | null => {
  const token = localStorage.getItem(`@${authTitle}:token`);
  return token;
};
