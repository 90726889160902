import React from 'react';
import api from '../../../../../../services/api';
import { useToast } from '../../../../../../hooks/Toast';

import { useAuth } from '../../../../../../hooks/Auth';
import { FiSend } from 'react-icons/fi';
import Whatsapp from '../../../../../../components/Whatsapp';
import { authTitle, defaultProjectHash } from '../../../../../../config';
import { FaList, FaMoneyBill, FaTable, FaTicketAlt, FaUser } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import downloadit from '../../../../../../utils/download';

interface ICreateModule {
    lineData: Record<string, any>;
}

export const CheckCourtesies: React.FC = () => {

    const { projectHash = defaultProjectHash } = useParams<Record<string, any>>();
    const { addToast } = useToast();


    const gerarRelatorioCortesias = async () => {

        addToast({ title: 'Gerando relatório', type: 'info' });

        await downloadit({ url: `/report-cortesies/${projectHash}` });


    }

    const gerarRelatorioFinanceiro = async () => {

        addToast({ title: 'Gerando relatório', type: 'info' });

        await downloadit({ url: `/report-cortesies-count/${projectHash}` });


    }

    const moduleUpdate = (): JSX.Element => (
        projectHash ? <> <button title='Número de cortesias' className="searchButton" type="button" onClick={gerarRelatorioFinanceiro}>
            <FaList />
        </button>

            <button title='Lista de cortesias' className="searchButton" type="button" onClick={gerarRelatorioCortesias}>
                <FaTable />
            </button> </>
            : <></>
    );

    return moduleUpdate();
}
