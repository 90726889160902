import React, { useState } from "react";
import { usePageCreatorContext } from "../../hooks/PageCreatorContext";
import EditStyle from "../EditStyle";
import { FaArrowLeft, FaArrowUp, FaArrowDown, FaEdit, FaTrash, FaPlus, FaCog, FaCube, FaAngleDown, FaAngleUp, FaPencilAlt, FaHome, FaPaintBrush } from "react-icons/fa";
import defaultProperties from "./defaultProperties";
import { Form } from "@unform/web";
import { useModal } from "../../../../../../hooks/Modal";
import Input from "../../../../core/components/Forms/Input";
import RichText from "../../../../core/components/Forms/RichText";
import Image from "../../../../core/components/Forms/ImageSingleGalleryInput";
import JsonInputList from "src/pages/DashboardPages/core/components/Forms/JsonInputList";
import TableInput from "src/pages/DashboardPages/core/components/Forms/TableInput";
interface ISidebarProps {
    currentView: string;
    setCurrentView: (view: string) => void;
    selectedIndices: { containerIndex?: number; sectionIndex?: number; rowIndex?: number; contentIndex?: number };
    setSelectedIndices: (indices: { containerIndex?: number; sectionIndex?: number; rowIndex?: number; contentIndex?: number }) => void;
    pageId: string;
}

interface IOptionContent {
    code: string;
    title: string;
    image: string;
}

function useUpdateContent() {
    const { addModal, removeModal } = useModal();

    const updateStyleModal = ({ indices, containers }) => {

        const content = containers?.[indices?.containerIndex]?.sections?.[indices?.sectionIndex]?.rows?.[indices?.rowIndex]?.content?.[indices?.contentIndex];


        addModal({
            title: "", key: "content", theme: "whiteModalMedium", content: <EditStyle closeEdit={() => { }}
                containerIndex={indices?.containerIndex}
                sectionIndex={indices?.sectionIndex}
                rowIndex={indices?.rowIndex}
                contentIndex={indices?.contentIndex}
                properties={content?.style || {}} />
        }
        );


    };


    return { updateStyleModal };
}








const Sidebar: React.FC<ISidebarProps> = ({
    currentView,
    setCurrentView,
    selectedIndices,
    setSelectedIndices,
    pageId
}) => {
    const {
        addContainer,
        containers,
        addContent,
        moveContainerUp,
        moveContainerDown,
        removeContainer,
        moveSectionUp,
        moveSectionDown,
        removeSection,
        addRow,
        moveRowUp,
        moveRowDown,
        removeRow,
        moveContentUp,
        moveContentDown,
        removeContent,
        updateContainerName,
        updateContent,
        saveContent,
        changeLanguage,
    } = usePageCreatorContext();

    const { removeModal } = useModal();
    const [expandedContainers, setExpandedContainers] = useState<number[]>([]);

    const handleBack = () => {
        setCurrentView("initial");
    };

    const handleHover = (indices: { containerIndex?: number; sectionIndex?: number; rowIndex?: number, contentIndex?: number }) => {
        //  setSelectedIndices(indices);
    };

    const toggleExpandContainer = (index: number) => {
        setExpandedContainers((prev) =>
            prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
        );
    };

    const handleRenameContainer = (containerIndex: number, name: string) => {
        updateContainerName(containerIndex, name);
    };

    const renderInitial = () => (
        <div>
            <div className="row gap full-width" style={{ justifyContent: "space-between" }}>
                <button className='greenButton2' onClick={() => saveContent(pageId)}>Salvar</button>

                <button className='greenButton2' onClick={() => removeModal("page-creator")}>Fechar</button>
            </div>

            <div className="row gap full-width" style={{ justifyContent: "center" }}>
                <button className='greenButton2' onClick={() => changeLanguage("ptBr")}>PT</button>
                <button className='greenButton2' onClick={() => changeLanguage("en")}>EN</button>
                <button className='greenButton2' onClick={() => changeLanguage("es")}>ES</button>
            </div>

            <div className="row gap full-width" style={{ justifyContent: "center" }}>

                <img className='scaleOnHover cursor' title='Adicionar 1 bloco' onClick={() => addContainer("single")} src="/pageCreator/container1.jpg" style={{ width: "100px" }} />

                <img className='scaleOnHover cursor' title='Adicionar 2 blocos' onClick={() => addContainer("double")} src="/pageCreator/container2.jpg" style={{ width: "100px" }} />

                <img className='scaleOnHover cursor' title='Adicionar 3 blocos' onClick={() => addContainer("triple")} src="/pageCreator/container3.jpg" style={{ width: "100px" }} />

            </div>
            <div className="container-list-menu" style={{ width: "100%" }}>
                {containers.map((container, containerIndex) => (
                    <div key={containerIndex} style={{ width: "100%" }}>
                        <div
                            className="container-menu-item"
                            onMouseEnter={() => handleHover({ containerIndex })}
                            onMouseLeave={() => handleHover({})}
                        >
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <div onClick={() => {
                                    setCurrentView("editStyle");
                                    setSelectedIndices({ containerIndex });
                                }} style={{ display: "flex", alignItems: "center" }}>

                                    <span>{container.name || `Container ${containerIndex + 1}`}</span>
                                </div>
                                <div className="actions">
                                    <FaArrowUp onClick={() => moveContainerUp(containerIndex)} />
                                    <FaArrowDown onClick={() => moveContainerDown(containerIndex)} />
                                    <FaEdit onClick={() => {
                                        setCurrentView("editStyle");
                                        setSelectedIndices({ containerIndex });
                                    }} />
                                    <FaTrash onClick={() => removeContainer(containerIndex)} />
                                    {expandedContainers.includes(containerIndex) ? (
                                        <FaAngleUp onClick={() => toggleExpandContainer(containerIndex)} />
                                    ) : (
                                        <FaAngleDown onClick={() => toggleExpandContainer(containerIndex)} />
                                    )}
                                </div>
                            </div>
                        </div>
                        {container.sections.map((section, sectionIndex) => (<>
                            <div key={sectionIndex} className="section-menu-item">
                                <div
                                    onClick={() => {
                                        setCurrentView("editStyle");
                                        setSelectedIndices({ containerIndex, sectionIndex });
                                    }}
                                    style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
                                >
                                    <div style={{ display: "flex", gap: "5px", alignItems: "center", justifyContent: "flex-start" }}>

                                        <div style={{ background: "orange", width: "30px", minWidth: "30px", padding: "10px" }} />

                                        <p> Bloco {sectionIndex + 1}</p>
                                    </div>
                                    <div className="actions">
                                        <FaArrowUp onClick={() => moveSectionUp(containerIndex, sectionIndex)} />
                                        <FaArrowDown onClick={() => moveSectionDown(containerIndex, sectionIndex)} />

                                        <FaTrash onClick={() => removeSection(containerIndex, sectionIndex)} />
                                    </div>
                                </div>

                            </div>

                            {section.rows.map((row, rowIndex) => (<>
                                <div key={rowIndex} className="row-menu-item">

                                    <div

                                        style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
                                    >
                                        <div onClick={() => {
                                            setCurrentView("contentItem");
                                            setSelectedIndices({ containerIndex, sectionIndex, rowIndex });
                                        }} style={{ display: "flex", gap: "5px", alignItems: "center", justifyContent: "flex-start", width: "100%" }}>

                                            <div style={{ background: "blue", width: "60px", minWidth: "60px", padding: "10px" }} />
                                            <p> Linha {rowIndex + 1}</p>
                                        </div>
                                        <div className="actions">
                                            <FaArrowUp onClick={() => moveRowUp(containerIndex, sectionIndex, rowIndex)} />
                                            <FaArrowDown onClick={() => moveRowDown(containerIndex, sectionIndex, rowIndex)} />
                                            <FaEdit onClick={() => {
                                                setCurrentView("editStyle");
                                                setSelectedIndices({ containerIndex, sectionIndex, rowIndex });
                                            }} />
                                            <FaTrash onClick={() => removeRow(containerIndex, sectionIndex, rowIndex)} />
                                            <FaPlus />
                                        </div>
                                    </div>

                                </div>
                                {row.content.map((contentItem, contentIndex) => (
                                    <div key={contentIndex} className="content-menu-item">

                                        <div

                                            style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
                                        ><div className='row-start'>
                                                <FaArrowUp onClick={() => moveContentUp(containerIndex, sectionIndex, rowIndex, contentIndex)} />
                                                <FaArrowDown onClick={() => moveContentDown(containerIndex, sectionIndex, rowIndex, contentIndex)} />

                                                <div onClick={() => {
                                                    setCurrentView("editContent");
                                                    setSelectedIndices({ containerIndex, sectionIndex, rowIndex, contentIndex });
                                                }} style={{ display: "flex", gap: "5px", alignItems: "center", justifyContent: "flex-start" }}>
                                                    <div style={{ background: "#ddd", width: "90px", minWidth: "90px", padding: "10px" }} />
                                                    <p> {contentItem.type}</p>
                                                </div>

                                            </div>

                                            <div className="actions">

                                                <FaTrash onClick={() => removeContent(containerIndex, sectionIndex, rowIndex, contentIndex)} />
                                            </div>
                                        </div>
                                    </div>
                                ))}

                            </>
                            ))}
                        </>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );

    const renderContainer = () => (
        <div>
            <button onClick={handleBack}><FaArrowLeft /> Menu</button>
            {containers.map((container, containerIndex) => (
                <div key={containerIndex} onClick={() => {
                    setCurrentView("editStyle");
                    setSelectedIndices({ containerIndex });
                }}>
                    {`Container ${containerIndex + 1}`}
                </div>
            ))}
        </div>
    );

    const renderSection = () => {
        const { containerIndex } = selectedIndices;
        if (containerIndex === undefined) return null;
        const sections = containers[containerIndex].sections;

        return (
            <div>
                <button onClick={handleBack}><FaArrowLeft /> Menu</button>
                {sections.map((section, sectionIndex) => (
                    <div key={sectionIndex} onClick={() => {
                        setCurrentView("row");
                        setSelectedIndices({ containerIndex, sectionIndex });
                    }}>
                        {`Section ${sectionIndex + 1}`}
                    </div>
                ))}
            </div>
        );
    };

    const renderRow = () => {
        const { containerIndex, sectionIndex } = selectedIndices;
        if (containerIndex === undefined || sectionIndex === undefined) return null;
        const rows = containers[containerIndex].sections[sectionIndex].rows;

        return (
            <div>
                <button onClick={handleBack}><FaArrowLeft /> Menu</button>
                {rows.map((row, rowIndex) => (
                    <div key={rowIndex} onClick={() => {
                        setCurrentView("contentItem");
                        setSelectedIndices({ containerIndex, sectionIndex, rowIndex });
                    }}>
                        {`Row ${rowIndex + 1}`}
                    </div>
                ))}
            </div>
        );
    };





    const renderContentItem = () => {
        const { containerIndex, sectionIndex, rowIndex } = selectedIndices;
        if (containerIndex === undefined || sectionIndex === undefined || rowIndex === undefined) return null;

        const handleAddContent = (type: string, data: Record<string, any>) => {

            addContent(containerIndex, sectionIndex, rowIndex, { type, value: data?.content?.value, style: data?.style } as any);
            // setCurrentView('initial');
        };




        const options: Array<IOptionContent> = [
            { code: "title", title: "Título", image: "/pageCreator/title.jpg" },
            { code: "subtitle", title: "Título", image: "/pageCreator/subtitle.jpg" },
            { code: "text", title: "Texto", image: "/pageCreator/text.jpg" },
            { code: "image", title: "Imagem", image: "/pageCreator/image.jpg" },
            { code: "video", title: "Vídeo", image: "/pageCreator/video.jpg" },
            { code: "videoGallery", title: "Galeria de Vídeos", image: "/pageCreator/video-gallery.jpg" },
            { code: "imageGallery", title: "Galeria de Imagens", image: "/pageCreator/image-gallery.jpg" },
            { code: "sponsors", title: "Patrocinadores", image: "/pageCreator/buttons.jpg" },
            { code: "link_list", title: "Lista de Links", image: "/pageCreator/buttons.jpg" },
            { code: "iframe", title: "Iframe", image: "/pageCreator/buttons.jpg" },
            { code: "grid_content", title: "Grid de imagens com conteúdo", image: "/pageCreator/buttons.jpg" },
            { code: "buttons", title: "Botões", image: "/pageCreator/buttons.jpg" },
            { code: "counter", title: "Contador", image: "/pageCreator/contador.jpg" },
            { code: "table", title: "Tabela", image: "/pageCreator/table.jpg" },
            { code: "contact", title: "Formulário de contato", image: "/pageCreator/widgets.jpg" },
            { code: "contact-list", title: "Lista de contatos", image: "/pageCreator/widgets.jpg" },
            { code: "customComponent", title: "Widgets", image: "/pageCreator/widgets.jpg" },
            { code: "landingpage", title: "Outra página", image: "/pageCreator/landingpages.jpg" },



        ];




        const defaultContent: Record<string, Function> = {




            title: () => handleAddContent("title", { content: { value: "Adicione um título", style: { computer: { "font-size": "32px", "line-height": "36px", "color": "#333", "max-width": "100%" } } } }),
            subtitle: () => handleAddContent("subtitle", { content: { value: "Adicione um subtítulo", style: { computer: { "font-size": "24px", "line-height": "28px", "color": "#333", "max-width": "100%" } } } }),
            text: () => handleAddContent("text", { content: { value: "<p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>", style: { computer: { "font-size": "14px", "line-height": "18px", "color": "#333", "max-width": "100%" } } } }),
            image: () => handleAddContent("image", { content: { value: "/pageCreator/image.jpg", style: { computer: { "max-width": "100%" } } } }),
            video: () => handleAddContent("video", { content: { value: "https://iframe.mediadelivery.net/embed/264913/4ae3e820-9c06-4b29-a6c8-ebcfb69a2840", style: { computer: { "max-width": "100%" } } } }),
            videoGallery: () => handleAddContent("video-gallery", { content: { value: [{ url: "https://iframe.mediadelivery.net/embed/264913/4ae3e820-9c06-4b29-a6c8-ebcfb69a2840" }], style: { computer: { "max-width": "100%" } } } }),
            imageGallery: () => handleAddContent("image-gallery", { content: { value: [{ image: "/pageCreator/image.jpeg" }, { image: "/pageCreator/image.jpeg" }], style: { computer: { "max-width": "100%" } } } }),
            table: () => handleAddContent("table", { content: { value: [[{ text: "" }]], searchable: "no", filters: [], style: { computer: { "max-width": "100%" } } } }),
            buttons: () => handleAddContent("buttons", { content: { value: [], style: { computer: { "font-size": "14px", "line-height": "18px", "color": "#fff", background: "#A71C20", "max-width": "100%" } } } }),
            counter: () => handleAddContent("counter", { content: { value: "2029-10-12T09:00:00-03:00", style: { computer: { "font-size": "14px", "line-height": "18px", "color": "#A71C20", background: "#fff", "max-width": "100%" } } } }),
            sponsors: () => handleAddContent("sponsors", { content: { value: [], style: { computer: { "font-size": "14px", "line-height": "18px", "color": "#A71C20", background: "#fff", "max-width": "100%" } } } }),
            grid_content: () => handleAddContent("grid-content", { content: { value: [], style: { computer: { "font-size": "14px", "line-height": "18px", "color": "#A71C20", background: "#fff", "max-width": "100%" } } } }),
            link_list: () => handleAddContent("link-list", { content: { value: [], style: { computer: { "font-size": "14px", "line-height": "18px", "color": "#A71C20", background: "#fff", "max-width": "100%" } } } }),
            iframe: () => handleAddContent("iframe", { content: { value: [{ url: "https://iframe.mediadelivery.net/embed/264913/4ae3e820-9c06-4b29-a6c8-ebcfb69a2840" }], style: { computer: { "font-size": "14px", "line-height": "18px", "color": "#A71C20", background: "#fff", "max-width": "100%" } } } }),
            contact: () => handleAddContent("contact", { content: { value: [], style: { computer: { "font-size": "14px", "line-height": "18px", "color": "#A71C20", background: "#fff", "max-width": "100%" } } } }),
            contact_list: () => handleAddContent("contact-list", { content: { value: [], style: { computer: { "font-size": "14px", "line-height": "18px", "color": "#A71C20", background: "#fff", "max-width": "100%" } } } }),

            customComponent: () => handleAddContent("custom-component", { content: { value: {}, style: { computer: { "max-width": "100%" } } } }),
            landingpage: () => handleAddContent("landingpage", { content: { value: {}, style: { computer: { "max-width": "100%" } } } }),

        };


        return (
            <div>
                <button onClick={handleBack}><FaArrowLeft /> Menu</button>
                <div>
                    <h3>Conteúdos</h3>


                    <div className='row gap' style={{ justifyContent: "center", background: "#fff" }}>
                        {options?.map(option => {
                            return <div className='column pad-sm shadow scaleOnHover'
                                onClick={() => defaultContent[option?.code] ? defaultContent[option.code]() : false}
                                style={{ border: "2px solid #bbb", padding: "10px", flexDirection: "column", display: "flex", alignItems: "center", borderRadius: "5px" }}>

                                <h2 style={{ fontSize: "12px", color: "#333", textAlign: "center", marginTop: "5px", maxWidth: "90px" }}>{option?.title}</h2>
                            </div>;
                        })}

                    </div>

                </div>
            </div>
        );
    };

    const renderEditStyle = () => {
        const { containerIndex = 0, sectionIndex, rowIndex, contentIndex } = selectedIndices;

        return (
            <EditStyle
                containerIndex={containerIndex}
                sectionIndex={sectionIndex}
                rowIndex={rowIndex}
                contentIndex={contentIndex}
                closeEdit={handleBack}
                properties={defaultProperties}
            />
        );
    };


    const renderContentEdit = () => {

        const content = containers?.[selectedIndices?.containerIndex || 0]?.sections?.[selectedIndices?.sectionIndex || 0]?.rows?.[selectedIndices?.rowIndex || 0]?.content?.[selectedIndices?.contentIndex || 0];

        return <Form className='form' onSubmit={(data) => {

            updateContent(selectedIndices, content?.type, data?.value,);

        }}

            initialData={{ value: content?.value }}
        >

            {content?.type === "title" ? <Input name="value" placeholder='Título' /> : <></>}
            {content?.type === "subtitle" ? <Input name="value" placeholder='Subtítulo' /> : <></>}
            {content?.type === "text" ? <RichText name="value" placeholder='Texto' /> : <></>}
            {content?.type === "video" ? <Input name="value" placeholder='URL' /> : <></>}
            {content?.type === "contact" ? <Input name="value" placeholder='HASH do formulário' /> : <></>}
            {content?.type === "image" ? <Image name="value" placeholder='Imagem' list={[{ name: "url", title: "URL", type: "image" }]} /> : <></>}

            {content?.type === "image-gallery" ? <JsonInputList name="value" placeholder='Galeria de imagens' list={[
                { name: "image", label: "Imagem", type: "image" },
                { name: "title", label: "Título", type: "input" },
                { name: "url", label: "Link", type: "input" },


                { name: "description", label: "Descritivo", type: "richText" },


            ]} /> : <></>}


            {content?.type === "sponsors" ? <JsonInputList name="value" placeholder='Logos de patrocinadores' list={[
                { name: "image", label: "Imagem", type: "image" },
                { name: "title", label: "Título", type: "input" },
                { name: "url", label: "Link", type: "input" },
                { name: "computer", label: "Tamanho no computador", type: "input" },
                { name: "mobile", label: "Tamanho no mobile", type: "input" },
            ]} /> : <></>}


            {content?.type === "link_list" ? <JsonInputList name="value" placeholder='Lista de Links' list={[

                { name: "title", label: "Título", type: "input" },
                {
                    name: "links", label: "Links", type: "jsonInputList", list: [
                        { name: "title", label: "Título", type: "input" },
                        { name: "url", label: "Link", type: "input" },
                        {
                            name: "type", label: "Tipo", type: "select", options: [{
                                label: "Mesma aba", value: "same"
                            },
                            {
                                label: "Nova Aba", value: "_Blank"
                            },

                            ]
                        },
                    ]
                },
                { name: "titleFontSize", label: "Tamanho do título", type: "input" },
                { name: "titleColor", label: "Cor do título", type: "color" },
                { name: "contentFontSize", label: "Tamanho da fonte dos links", type: "input" },
                { name: "contentColor", label: "Cor dos links", type: "color" },
            ]} /> : <></>}


            {content?.type === "grid_content" ? <JsonInputList name="value" placeholder='Grade de imagens com conteúdo' list={[
                { name: "image", label: "Imagem", type: "image" },
                { name: "title", label: "Título", type: "input" },
                { name: "subtitle", label: "Subtítulo", type: "richText" },
                { name: "description", label: "Descritivo", type: "richText" },



                {
                    name: "imagens", label: "Galeria de imagens", type: "jsonInputList", list: [
                        { name: "image", label: "Imagem", type: "image" },
                        { name: "title", label: "Título", type: "input" },
                        { name: "url", label: "Link", type: "input" },


                        { name: "description", label: "Descritivo", type: "richText" },


                    ]
                },
                {
                    name: "videos", label: "Galeria de Vídeos", type: "jsonInputList", list: [
                        { name: "title", label: "Título", type: "input" },
                        { name: "description", label: "Descritivo", type: "richText" },
                        { name: "url", label: "Link", type: "input" },
                        {
                            name: "type", label: "Tipo", type: "select", options: [{
                                label: "Vertical", value: "vertical"
                            },
                            {
                                label: "Horizontal", value: "horizontal"
                            },
                            {
                                label: "Livre", value: "free"
                            }

                            ]
                        },
                        { name: "width", label: "Largura", type: "input" },
                        { name: "minHeight", label: "Altura mínima", type: "input" },
                    ]
                },

                {
                    name: "buttons", label: "Botões", type: "jsonInputList", list: [
                        { name: "title", label: "Título", type: "input" },
                        { name: "url", label: "Link", type: "input" },
                    ]
                },


                { name: "width", label: "Largura", type: "input" },
                { name: "background", label: "Fundo", type: "input" },
                { name: "color", label: "Cor do Texto", type: "input" },

            ]} /> : <></>}


            {content?.type === "iframe" ? <JsonInputList name="value" placeholder='Iframe' list={[
                { name: "title", label: "Título", type: "input" },
                { name: "description", label: "Descritivo", type: "richText" },
                { name: "url", label: "Link", type: "input" },
                {
                    name: "type", label: "Tipo", type: "select", options: [{
                        label: "Vertical", value: "vertical"
                    },
                    {
                        label: "Horizontal", value: "horizontal"
                    },
                    {
                        label: "Livre", value: "free"
                    }

                    ]
                },
                { name: "width", label: "Largura", type: "input" },
                { name: "minHeight", label: "Altura mínima", type: "input" },
            ]} /> : <></>}


            {content?.type === "video-gallery" ? <JsonInputList name="value" placeholder='Galeria de vídeos' list={[

                { name: "title", label: "Título", type: "input" },
                { name: "url", label: "Link", type: "input" },


            ]} /> : <></>}

            {content?.type === "contact" ? <JsonInputList name="value" placeholder='Formulário de contato' list={[

                { name: "hash", label: "Hash do formulário", type: "input" },
                { name: "project_id", label: "Código do projeto", type: "input" },


            ]} /> : <></>}


            {content?.type === "contact-list" ? <JsonInputList name="value" placeholder='Formulário de contato' list={[

                { name: "title", label: "Título", type: "input" },
                { name: "description", label: "Descritivo", type: "richText" },
                {
                    name: "social", label: "Redes sociais", type: "jsonInputList", list: [
                        { label: "Título", name: "title", type: "input" },
                        { label: "value", name: "value", type: "input" },
                        {
                            label: "Tipo", name: "type", type: "select", options: [
                                { text: "Whatsapp", label: "Whatsapp", value: "whatsapp" },
                                { text: "Facebook", label: "Facebook", value: "facebook" },
                                { text: "Instagram", label: "Instagram", value: "instagram" },
                                { text: "Linkedin", label: "Linkedin", value: "linkedin" },
                                { text: "Twitter", label: "Twitter", value: "twitter" },
                            ]
                        },

                    ]
                },

            ]} /> : <></>}


            {content?.type === "table" ? <TableInput name="value" placeholder='Tabela' />
                : <></>}

            {content?.type === "buttons" ? <JsonInputList name="value" placeholder='Botões' list={[
                { name: "title", label: "Título", type: "input" },
                { name: "url", label: "Link", type: "input" },
                {
                    name: "type", label: "Tipo", type: "select", options: [{
                        label: "Interno", value: "internal"
                    },
                    {
                        label: "Externo", value: "external"
                    },


                    ]
                },
            ]} /> : <></>}

            {content?.type === "counter" ? <Input name="value" placeholder='Data de referência' /> : <></>}
            {content?.type === "landingpage" ? <Input name="value" placeholder='Hash' /> : <></>}
            <button className='defaultButton'>Salvar</button>
        </Form>;
    };

    return (
        <div className="sidebar">
            <button onClick={() => { setCurrentView("initial"); }}><FaHome /> Menu</button>
            {currentView === "initial" && renderInitial()}
            {currentView === "container" && renderContainer()}
            {currentView === "section" && renderSection()}
            {currentView === "row" && renderRow()}
            {currentView === "contentItem" && <div className='column full-width'>
                <div className='row'>
                    <button onClick={() => setCurrentView("contentItem")}>Adicionar conteúdo</button>
                    <button onClick={() => setCurrentView("editStyle")}>Estilo</button>
                </div> {renderContentItem()}</div>}
            {currentView === "editStyle" && <div className='column full-width'>
                <div className='row'>
                    {selectedIndices?.contentIndex !== undefined ? <button onClick={() => setCurrentView("editContent")}>Editar</button> : <></>}
                    <button onClick={() => setCurrentView("editStyle")}>Estilo</button>
                    {selectedIndices?.sectionIndex !== undefined && selectedIndices?.rowIndex === undefined ? <button onClick={() => addRow(selectedIndices?.containerIndex || 0, selectedIndices?.sectionIndex || 0)}>Adicionar linha</button> : <></>}

                </div> {renderEditStyle()}</div>}
            {currentView === "editContent" && <div className='column full-width'>
                <div className='row'>
                    {selectedIndices?.contentIndex !== undefined ? <button onClick={() => setCurrentView("editContent")}>Editar</button> : <></>}

                    {selectedIndices?.contentIndex === undefined && selectedIndices?.rowIndex !== undefined ? <button onClick={() => setCurrentView("contentItem")}>Adicionar conteúdo</button> : <></>}


                    <button onClick={() => setCurrentView("editStyle")}>Estilo</button>
                </div>
                {renderContentEdit()}

            </div>}
        </div>
    );
};

export default Sidebar;