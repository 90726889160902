import styled, { keyframes } from 'styled-components';

const rotateAnimation = keyframes`
0% {
  filter: blur(10);
}
100% {
  transform : rotate(100deg)
}
`;

export const Container = styled.div`
  display: flex;

  transition: opacity 0.2s;
  font-weight: bold;
  margin: auto;
  justify-content: center;

  &:hover {
    opacity: 0.9;
  }

  > div {
    padding: 5px;
    display: flex;
    width: 70px;
    height: 70px;
    border-radius: 0px;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
    justify-content: center;
    align-items: center;
    background: rgb(0, 102, 178);
    margin: 5px;
    flex-direction: column;

    > h3 {
      color: #fff;
      font-size: 28px;
    }

    > p {
      color: #fff;
      font-size: 12px;
    }
  }

  > p {
    font-size: 32px;
    margin-top: 10px;
    svg {
      animation: ${rotateAnimation} 1s infinite;
    }
  }
`;
