import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { urlWebsite } from 'src/config';

import { Container, Sponsor, Apoio, SponsorGrid, ApoioGrid, ApoioImg } from './styles';


interface ISponsor {
  url: string;
  link_url?: string;
  computer?: string;
  mobile?: string;

}
interface IParamsURL {
  projectHash?: string;
  eventHash?: string;
}
interface ISponsors {
  title: string;
  title_font_color: string;
  title_font_size: string;
  position: number;
  sponsors: Array<ISponsor>;
  size?: string;
  status?: string;
}

interface IProps {
  data?: Record<string, any>;
}

const SponsorsWidgets: React.FC<IProps> = ({ data }) => {


  return (
    <>


      {data?.sponsors.length > 0 && <Container id="patrocinadores" >
        { /* <h2 style={{ color: 'rgb(0,14,64)', fontSize: '32px', marginTop: '10px', marginBottom: '25px' }} >Patrocinadores</h2> */}






        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start', minWidth: '350px', maxWidth: '1200px', margin: '10px auto' }}>
          {data?.title ? <p style={{ color: data?.color ? data?.color : 'rgb(0, 14, 64)', fontWeight: 'bold', fontSize: data?.fontSize ? data?.fontSize : '24px', marginTop: '20px', marginBottom: '20px' }}>{data.title}</p> : <></>}
          <ApoioGrid size={'p'}>
            {data?.sponsors && data?.sponsors.map(apoio => (apoio.link_url ? <a href={apoio.link_url} target="_BLANK" style={{ background: '#fff' }}><ApoioImg computer={apoio?.computer} mobile={apoio?.mobile} src={`${urlWebsite}/${apoio.url}`} key={apoio.url} /></a> : <div style={{ background: '#fff' }} ><ApoioImg computer={apoio?.computer} mobile={apoio?.mobile} src={`${urlWebsite}/${apoio.url}`} key={apoio.url} /></div>))}

          </ApoioGrid>
        </div>





      </Container >}

    </>
  );
};

export default SponsorsWidgets;
