import React, { useEffect, useState } from "react";

import { IconType } from "react-icons/lib";

import { Container, ContainerWhite, Paragraph } from "./styles";


import { urlWebsite } from "../../../../../../config";
import IframePlayer from "../../../../../../components/UIComponents/IframePlayer";
import VideoBackground from "../../../../../../components/VideoBackground";
import ContainerList from "src/pages/DashboardPages/features/PageCreator/modules/ContainerList";



const PageCreatorTemplate: React.FC<Record<string, any>> = ({ language, theme, pageContent }) => {
  const { hash } = pageContent;


  return (<ContainerList language={language} editable={false} containers={pageContent?.content?.containers || []} page={pageContent?.content?.page || {}} context={{}} />

  );
};
export default PageCreatorTemplate;
