import React, { useEffect, useState } from 'react';
import Sidebar from './modules/Sidebar';
import ContainerList from './modules/ContainerList';
import { PageCreatorProvider, usePageCreatorContext } from './hooks/PageCreatorContext';
import { Container } from './styles';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

interface IProps {
  lineData: Record<string, any>;
}

const ContainerBlock = () => {

  const context = usePageCreatorContext();
  const language = context?.currentLanguage;
  const containers = context?.containers || [];

  const page = context?.page || {};


  return <ContainerList
    editable={true}
    containers={containers}
    page={page}
    context={context}
    language={language}
  />

}

const PageCreator: React.FC<IProps> = ({ lineData }) => {
  const [currentView, setCurrentView] = useState<string>('initial');
  const [selectedIndices, setSelectedIndices] = useState<{ containerIndex?: number; sectionIndex?: number; rowIndex?: number; contentIndex?: number }>({});
  const [previewMode, setPreviewMode] = useState(false);



  return (
    <PageCreatorProvider content={lineData}>

      <Container>
        <div className={`page-creator ${previewMode ? 'preview-mode' : ''}`}>
          <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start' }}>
            <ContainerBlock />
          </div>
          {!previewMode && (
            <Sidebar
              pageId={lineData?._id?.toString()}
              currentView={currentView}
              setCurrentView={setCurrentView}
              selectedIndices={selectedIndices}
              setSelectedIndices={setSelectedIndices}
            />
          )}
          <div className="preview-toggle" onClick={() => setPreviewMode(!previewMode)}>
            {previewMode ? <FaEyeSlash /> : <FaEye />}
          </div>
        </div>
      </Container>
    </PageCreatorProvider>
  );
};

export default PageCreator;