import styled from "styled-components";
import { defaultTheme } from "../../../../../../../../config";



interface ILink {
  background: string;
  extend?: boolean;
}

export const Icon = styled.a<ILink>`
    width: ${props => (props.extend ? 'auto' : '50px')};
    height: 50px;
    padding: ${props => (props.extend ? '5px 15px;' : ' 5px;')};
    margin: ${props => (props.extend ? '5px 15px;' : '0px 5px;')};
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 32px;
    transition: 0.2s all;
    text-decoration: none;
    background: none;
  
    border-radius: 8px;
    opacity: 0.7;
    color: ${props => (props.background ? props.background : '#444')};
  
    svg {
      font-size: 40px;
      color: ${defaultTheme.defaultColor};
    }
  
    span {
      font-size: 18px;
      margin-left: 10px;
      text-decoration: none;
    }
  
    &:hover {
      font-size: 24px;
      opacity: 1;
    }
  `;


interface IIconSet {
  column: boolean;
}

export const IconSet = styled.div<IIconSet>`
    display: flex;
    justify-content: center;
    align-items: center;
  
    flex-direction: column;
  
    > div {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: ${props => (props.column ? 'column' : 'row')};
    }
  `;


export const TextMessage = styled.p`
font-size: 24px;
font-weight: bold;
margin-bottom: 20px;
display: flex;
text-align: center;
align-items: center;
justify-content: center;

max-width: 200px;
display: flex;

@media (max-width: 740px) {
  margin-bottom: 10px;
  text-align: center;
  align-items: center;
}
`;
