import React from "react";
import { Link } from "react-router-dom";
import ContagemRegressiva from "src/components/ContagemRegressiva";
import ImageGallery from "src/components/ImageGallery";
import VideoGallery from "src/components/VideoGallery";
import { urlWebsite } from "src/config";
import EventsPages from "src/PagesApplication/HomeApplication/modules/Paginas";
import IframePlayer from "../../../../../../components/IframePlayer";
import IframeWidget from "../widgets/IframeWidget";
import { IContentItem } from "../../hooks/PageCreatorContext";
import { ButtonStyle } from "../../styles";
import GridList from "../widgets/GridList";
import SponsorsWidgets from "../widgets/Sponsors";
import TableWidget from "../widgets/Table";
import LinkListWidget from "../widgets/LinkListWidget";
import { ImageStyle, ParagraphStyle, SubTitleStyle, TitleStyle, VideoStyle } from "./styles";
import Contact from "../widgets/Contact";
import ContactList from "../widgets/ContactList";

interface IContentItemProps {
    contentItem: IContentItem;
    editable?: boolean;
    editContent?: Function;
    context: Record<string, any>;
    language: string;
}

const ContentItem: React.FC<IContentItemProps> = ({ language, context = {}, editContent = () => { }, editable = false, contentItem }) => {


    const content = contentItem?.[`value_${language}`] ? contentItem?.[`value_${language}`] : contentItem?.value;
    const computerStyle = contentItem?.style?.computer || {};
    const mobileStyle = contentItem?.style?.mobile || {};

    switch (contentItem.type) {
        case "title":
            return <TitleStyle onClick={() => editable ? editContent() : false} computer={computerStyle} mobile={mobileStyle}>{content || "Adicione um Título"}</TitleStyle>;
        case "subtitle":
            return <SubTitleStyle onClick={() => editable ? editContent() : false} computer={computerStyle} mobile={mobileStyle} >{content || "Adicione um Subtítulo"}</SubTitleStyle>;

        case "text":
            return <ParagraphStyle onClick={() => editable ? editContent() : false} dangerouslySetInnerHTML={{ __html: content || "<p>Adicione um texto </p>" }} computer={computerStyle} mobile={mobileStyle} />;

        case "image":
            return <ImageStyle onClick={() => editable ? editContent() : false} computer={computerStyle} mobile={mobileStyle} src={`${urlWebsite}/${content || "/apoio/image.jpeg"}`} alt="" />;
        case "video":
            return <VideoStyle onClick={() => editable ? editContent() : false} computer={computerStyle} mobile={mobileStyle}><IframePlayer id={content} /></VideoStyle>;
        case "video-gallery":
            return (
                <div className='full-width' onClick={() => editable ? editContent() : false}> <VideoGallery data={content} /></div>
            );
        case "link-list":
            return (
                <div className='full-width' onClick={() => editable ? editContent() : false}><LinkListWidget data={content} /></div>
            );
        case "iframe":
            return (
                <div className='full-width' onClick={() => editable ? editContent() : false}><IframeWidget data={content} computer={computerStyle} mobile={mobileStyle} /></div>
            );
        case "image-gallery":
            return (
                <div className='full-width' onClick={() => editable ? editContent() : false}><ImageGallery data={content} /></div>
            );

        case "grid-content":
            return (
                <div className='full-width' onClick={() => editable ? editContent() : false}><GridList list={content || []} /></div>
            );
        case "contact":
            return (
                <div className='full-width' onClick={() => editable ? editContent() : false}><Contact data={content?.[0] || {}} /></div>
            );

        case "contact-list":
            return (
                <div className='full-width' onClick={() => editable ? editContent() : false}><ContactList data={content} /></div>
            );
        case "sponsors":
            return (
                <div className='full-width' onClick={() => editable ? editContent() : false}><SponsorsWidgets data={content} /></div>
            );

        case "table":
            return (
                <div className='full-width' onClick={() => editable ? editContent() : false}>{<TableWidget data={content} computer={computerStyle} mobile={mobileStyle} />}</div>
            );
        case "buttons":
            return <div style={{ display: "flex", gap: "10px", justifyContent: "flex-start", alignItems: "center" }} onClick={() => editable ? editContent() : false}>
                {content?.map(buttonInfo => buttonInfo?.type === "external" ? <a target='_BLANK' href={buttonInfo?.url} rel="noreferrer"><ButtonStyle className='greenButton2 shadow' computer={computerStyle} mobile={mobileStyle}>{buttonInfo?.title}</ButtonStyle></a> : <Link to={buttonInfo?.url}><ButtonStyle className='greenButton2 shadow' computer={computerStyle} mobile={mobileStyle}>{buttonInfo?.title}</ButtonStyle></Link>)}
            </div>;
        case "counter":
            return <div style={{ width: "auto" }} onClick={() => editable ? editContent() : false}><ContagemRegressiva dataDesejada={content || "2020-10-12T09:00:00-03:00"} /></div>;

        case "custom":
            return <div className="content-item">Custom Component: {content?.name}</div>;
        case "landingpage":
            return <EventsPages hash={content} project_id={content?.project_id} />;
        default:
            return null;
    }
};

export default ContentItem;