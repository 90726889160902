import React, { useEffect, useState } from 'react';

import { IconType } from 'react-icons/lib';

import { Container, ContainerWhite, Paragraph } from './styles';

import Vimeo from '../../components/Vimeo';
import VideoGallery from '../../components/VideoGallery';
import ImageGallery from '../../components/ImageGallery';
import Speakers from '../Speakers';
import api from '../../services/api';
import { useParams } from 'react-router-dom';
import { urlWebsite } from '../../config';
import IframePlayer from '../../components/IframePlayer';
import { useConfiguration } from '../../hooks/Configuration';
import SliderComponent from '../../components/SliderContainer';
import Paginas from '../../PagesApplication/HomeApplication/modules/Paginas';
interface SignInData {
  email: string;
  password: string;
}

interface IPublicData {
  icon: IconType;
  title: string;
  text: string;
}

interface IProps {
  hash?: string;
}

const EventsPages: React.FC<IProps> = ({ hash = '' }) => {
  const { configuration } = useConfiguration();
  const { projectHash = configuration?.url, url = hash } = useParams<Record<string, any>>();
  const [ready, setReady] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pageContent, setPageContent] = useState<Record<string, any>>({});

  const theme = configuration?.current_event_id_response?.theme || {};

  const load = async () => {
    if (url) {
      setLoading(true);

      const response = await api.get(`/show-page/${projectHash}/${url}`);

      if (response) {
        setPageContent(response.data);
        setReady(true);
      }
      else {
        setReady(true);
      }
      setLoading(false);
    }
  }

  useEffect(() => {

    load();

  }, [url, projectHash])

  const images = pageContent?.images?.length > 0 ? pageContent?.images?.reduce((prev, image) => {

    prev.push(<img style={{ width: '100%', maxWidth: '100%' }} src={`${urlWebsite}/${image?.url}`} />);
    return prev;
  }, []) : [];

  return (
    <>
      <Paginas hash={url} />
    </>
  );
};
export default EventsPages;
